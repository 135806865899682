<template>
  <div class="orderInfo" v-if="logData">
    <Header :isGoBack="false" :path="path" :title="title" theme="light" />
    <div class="zone__main__02">
      <div class="content_2">
        <div class="box__close mb_8px" v-if="logData.status == 1 || logData.status == 5">
          <div class="box__img" @click="$router.push(path)">
            <img src="../../assets/images/public/ic_payment_tick.png" alt="" />
          </div>
          <div class="text__01">订单完成</div>
        </div>
        <div class="box__close mb_8px" v-else>
          <div class="box__img" @click="$router.push(path)">
            <img src="../../assets/images/public/ic_order_cancel_the_order.png" alt="" />
          </div>
          <div class="text__01">订单取消</div>
        </div>
        <div class="w100 flex-sb mb_8px">
          <div>
            卖家名称 : <span>{{ logData.traderName }}</span>
          </div>
          <div class="save" v-if="isTraderRelations != true" @click="postTraderRelations({ traderId: logData.traderId })">
            [收藏]
          </div>
        </div>
        <div class="w100 flex-sb mb_8px" v-if="logData.statusDesc == '超时关闭'">
          <div>
            取消类型 : <span>{{ logData.statusDesc }}</span>
          </div>
        </div>

        <div class="w100 mb_8px">
          付款方式 : <span>{{ logData.traderAccounts[0].channelName }}</span>
        </div>
        <div class="w100 mb_8px">
          订单金额 : <span>¥ {{ logData.orderAmount }}</span>
        </div>
        <div class="w100 flex-sb mb_8px">
          <div>
            订单编号 : <span>{{ logData.orderNo }}</span>
          </div>
          <div v-clipboard:copy="logData.orderNo" v-clipboard:success="handleCopySuccess" v-clipboard:error="handleCopyError" style="white-space:nowrap;">
            [复制]
          </div>
        </div>
        <div class="w100 mb_8px" v-if="logData.statusDesc != '超时关闭'">
          订单状态 : <span>{{ logData.statusDesc }}</span>
        </div>
        <div class="w100 mb_8px">
          创建时间 : <span>{{ logData.createdAt }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import { mapState, mapActions } from "vuex";
import { Message } from "element-ui";
export default {
  name: "orderInfo",
  components: {
    Header,
  },
  data() {
    return {
      title: "订单状态",
      data: [],
      isTraderRelations: false,
      path: "/",
    };
  },
  computed: {
    ...mapState("orders", ["logData"]),
    ...mapState("trader", ["traderRelationsData"]),
  },
  watch: {
    traderRelationsData() {
      this.findTraderRelations();
    },
    logData() {
      this.data = {
        amount: this.logData.orderAmount,
        orderNo: this.logData.orderNo,
        traderName: this.logData.traderName,
        account: this.logData.traderAccounts[0].account,
        channelCode: this.logData.traderAccounts[0].channelCode,
        accountName: this.logData.traderAccounts[0].accountName,
        channelName: this.logData.traderAccounts[0].channelName,
      };
      this.getTraderRelations({});
    },
  },
  created() {
    this.init();
    // var data = {
    //   amount: this.logData.orderAmount,
    //   orderNo: this.logData.orderNo,
    //   traderName: this.logData.traderName,
    //   account: this.logData.traderAccounts[0].account,
    //   channelCode: this.logData.traderAccounts[0].channelCode,
    //   accountName: this.logData.traderAccounts[0].accountName,
    //   channelName: this.logData.traderAccounts[0].channelName,
    // };
  },
  methods: {
    ...mapActions("orders", ["getLogId"]),
    ...mapActions("trader", ["getTraderRelations", "postTraderRelations"]),
    init() {
      this.path = this.$route.query.comeFrom ? this.$route.query.comeFrom : "/";
      this.getLogId(this.$route.query.orderId);
    },
    findTraderRelations() {
      const self = this;
      this.traderRelationsData.forEach((e) => {
        if (e.traderId == this.logData.traderId) {
          self.isTraderRelations = true;
        }
      });
    },
    handleCopySuccess() {
      Message({
        message: "复制成功",
        iconClass: "x",
        center: true,
        customClass: "sucess_message",
      });
    },
    handleCopyError() {
      Message({
        message: "复制失败",
        iconClass: "x",
        center: true,
        customClass: "sucess_message",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.orderInfo {
  width: 100%;
  height: calc(100vh - 52px);
  overflow-y: scroll;

  .zone__main {
    width: 100%;
    padding: 4rem 1rem;

    .text_01 {
      font-weight: bold;
      font-size: 1.2rem;
      padding-bottom: 1rem;
    }

    .text_02 {
      color: var(--gray);
      font-size: 0.9rem;
      padding-bottom: 5rem;
    }

    .box__img {
      width: 50%;
      margin: auto;

      img {
        width: 100%;
      }
    }

    .btn__01,
    .btn__02 {
      width: 100%;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      border-radius: 20px;
      margin: auto;
    }

    .btn__01 {
      background-color: var(--orange);
      color: var(--white);
      margin-top: 6rem;
      margin-bottom: 1rem;
    }

    .btn__02 {
      background-color: var(--deep-gray);
      color: var(--light-black);
    }
  }

  .zone__main__02 {
    display: flex;
    flex-wrap: wrap;
    padding: 4rem 1rem;

    .content_2 {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      padding-top: 1.5rem;
      flex-wrap: wrap;
      font-size: 0.8rem;

      & > div {
        width: 100%;
        text-align: left;
        color: var(--gray);

        div {
          color: var(--gray);
        }

        span {
          color: var(--black);
        }

        .save {
          color: var(--orange);
        }
      }

      .box__close {
        width: 100%;

        .box__img {
          width: 2rem;
          margin: auto;

          img {
            width: 100%;
          }
        }

        .text__01 {
          margin-top: 0.5rem;
          color: var(--font-gray);
          text-align: center;
        }
      }
    }
  }
}

.flex-sb {
  display: flex;
  justify-content: space-between;
}
</style>
